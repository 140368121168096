/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import {
  BrowserMultiFormatReader,
} from '@zxing/library';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TRANSLATIONS } from '../../_constants';
import SearchBarContext from '../../_contexts/searchBarContext';

function SearchBar({ language }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [focused, setFocused] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);
  const [barcodeResult, setBarcodeResult] = useState('');
  const inputRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const codeReaderRef = useRef(null);
  const { mobileSearch, toggleMobileSearch } = useContext(SearchBarContext);
  const history = useHistory();
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const checkMobileDevice = () => {
      setIsMobileDevice(window.innerWidth <= 1279);
    };
    checkMobileDevice();
    window.addEventListener('resize', checkMobileDevice);
    return () => window.removeEventListener('resize', checkMobileDevice);
  }, []);

  useEffect(() => {
    if (cameraActive) {
      startScanner();
    } else {
      stopScanner();
    }
    return () => stopScanner();
  }, [cameraActive]);

  useEffect(() => {
    if (mobileSearch && inputRef.current) {
      inputRef.current.focus(); // Focus the input field when mobileSearch is true
    }
  }, [mobileSearch]);

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
    if (e.key === 'Escape') {
      inputRef.current.blur();
    }
  };

  const handleSearchClick = (term = searchTerm) => {
    if (term) {
      toggleMobileSearch();
      history.push(`/${language}/search/${term}`);
      setSearchTerm('');
      setCameraActive(false);
      setFocused(false);
      toggleMobileSearch();
    }
  };

  const stopScanner = () => {
    if (codeReaderRef.current) {
      codeReaderRef.current.reset();
    }

    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const startScanner = async () => {
    if (!isMobileDevice) return;

    if (!codeReaderRef.current) {
      codeReaderRef.current = new BrowserMultiFormatReader();
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } } // Request the rear camera
      });
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;

        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((err) => {
            console.error('Error while playing video:', err);
          });
        };
      }

      codeReaderRef.current.decodeFromVideoDevice(
        null,
        videoRef.current,
        (result, err) => {
          if (result) {
            const barcode = result.text;
            setBarcodeResult(barcode);
            history.push(`/${language}/search/${barcode}`);
            stopScanner();
            setCameraActive(false);
          }
          if (err && !(err.name === 'NotFoundException')) {
            console.error('Barcode scanning error:', err);
          }
        },
      );
    } catch (err) {
      console.error('Error accessing webcam:', err);
    }
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        className={`d-flex input-search ${focused ? 'show' : ''} ${mobileSearch ? 'mobile-open' : ''}`}
      >
        <div className="search-overlay" />
        <input
          type="text"
          ref={inputRef}
          placeholder={TRANSLATIONS[language].search}
          value={searchTerm}
          onChange={(e) => handleChange(e.target.value)}
          onKeyUp={handleSubmit}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {mobileSearch
          && (
            <>
              <span className="arrow" onClick={toggleMobileSearch} />
              <span className={`zoom ${focused ? 'show' : ''}`} />
              <span
                className="cam"
                onClick={() => setCameraActive(true)}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa fa-camera" aria-hidden="true" />
              </span>
            </>
          )}
      </div>

      {cameraActive && isMobileDevice && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 9999,
          }}
        >
          <div
            className="close-cam"
            onClick={() => setCameraActive(false)}
          >
            X
          </div>
          <video
            ref={videoRef}
            style={{
              width: '100%',
              height: '100vh',
              border: '1px solid black',
              zIndex: 2000,
            }}
            autoPlay
          >
            <track kind="metadata" />
          </video>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.locale,
});

export default connect(mapStateToProps)(SearchBar);
