import { useState, useEffect } from 'react';
import './FloatingButton.scss';
import userWay from '../assets/images/userWay.svg';

function FloatingButton() {
  const [position, setPosition] = useState(() => {
    if (window.innerWidth <= 767) {
      return { x: window.innerWidth - 50 - 10, y: window.innerHeight - 50 - 80 };
    }
    return { x: window.innerWidth - 80 - 60, y: window.innerHeight - 80 - 100 };
  });

  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [wasDragging, setWasDragging] = useState(false);

  const getButtonSize = () => (window.innerWidth <= 767 ? 50 : 80);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setWasDragging(false);
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

    setStartPos({
      x: clientX - position.x,
      y: clientY - position.y,
    });

    document.body.classList.add('no-scroll');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setWasDragging(true);
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

    setPosition({
      x: clientX - startPos.x,
      y: clientY - startPos.y,
    });
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      snapToSide();
      document.body.classList.remove('no-scroll');
    }
  };

  const snapToSide = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonSize = getButtonSize();
    const isMobile = window.innerWidth <= 767;

    let newY = position.y;
    let newX = position.x;

    if (position.y < 30) newY = 30;
    if (position.y > windowHeight - buttonSize - 30) newY = windowHeight - buttonSize - 30;

    if (isMobile) {
      if (position.x > windowWidth - buttonSize - 10) {
        newX = windowWidth - buttonSize - 10;
      } else if (position.x < 10) {
        newX = 10;
      }
    } else if (position.x < 30) {
      newX = 30;
    } else if (position.x > windowWidth / 2) {
      newX = windowWidth - buttonSize - 30 - (isMobile ? 10 : 30);
    } else {
      newX = 30;
    }

    setPosition({ x: newX, y: newY });
  };

  useEffect(() => {
    const handleResize = () => {
      setPosition((prev) => {
        const buttonSize = getButtonSize();
        return {
          x: Math.min(prev.x, window.innerWidth - buttonSize - 10),
          y: Math.min(prev.y, window.innerHeight - buttonSize - 30),
        };
      });
    };

    const preventClick = (e) => {
      if (isDragging || wasDragging) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const preventScroll = (e) => {
      e.preventDefault();
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleMouseMove, { passive: false });
      document.addEventListener('touchend', handleMouseUp);
      document.addEventListener('touchmove', preventScroll, { passive: false });
      document.addEventListener('wheel', preventScroll, { passive: false });
      document.addEventListener('keydown', preventScroll);
    }

    document.addEventListener('click', preventClick, true);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('click', preventClick, true);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('touchmove', preventScroll);
      document.removeEventListener('wheel', preventScroll);
      document.removeEventListener('keydown', preventScroll);
    };
  }, [isDragging, position]);

  const handleMouseClick = (e) => {
    if (wasDragging) {
      e.preventDefault();
      e.stopPropagation();
      setWasDragging(false);
      return;
    }
    console.log('Floating button clicked');
  };

  return (
    <div
      className="floating-snap-btn-wrapper"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transition: isDragging ? 'none' : '0.3s ease-in-out',
      }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      id="accessibilityWidget"
      onClick={handleMouseClick}
    >
      <img src={userWay} alt="a-btn" className="fab-btn" draggable="false" />
    </div>
  );
}

export default FloatingButton;
